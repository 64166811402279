import styles                                                        from '../styles/404.module.sass'
import Header                                                        from '../components/Header'
import React, {useEffect, useRef}                                    from 'react'
import Head                                                          from 'next/head'
import Logo_SVG                                                      from '../components/svg/Logo_SVG'
import Link                                                          from 'next/link'
import {Bodies, Body, Engine, Mouse, MouseConstraint, Render, World} from 'matter-js'
import * as PIXI                                                     from 'pixi.js'

const Page404 = ({firstLoad}) => {
	// References
	const contentRef = useRef(null)

	return (
		<>
			<Head>
				<title>Page introuvable</title>
			</Head>
			<div id={'smooth-wrapper'}>
				<div
					id='smooth-content'
				>
					<div className={styles.page_content}>
						<Header firstLoad={firstLoad}/>

						{/*Middle*/}
						<div className={styles.middle}>
							<div
								className={styles.content}
								ref={contentRef}
							>
								<PhysicsSpan contentRef={contentRef}/>

								<Logo_SVG className={styles.logo}/>

								{/*Middle*/}
								<div className={styles.middle}>
									<span className={styles.error_404}>Erreur 404</span>
									<div
										className={styles.error_text}
									>
										<span>Désolé, la page que </span><span>vous cherchez n&apos;existe plus</span></div>

									{/*Back to site button*/}
									<Link
										href={'/'}
										className={styles.back_to_site}
									><span className={styles.text}>Retourner sur le site</span></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const PhysicsSpan = ({contentRef}) => {
	const canvasRef = useRef(null)

	useEffect(
		() => {

			// Define if the device is a mobile
			const isMobile = window.innerWidth
			                 < 768

			// Get canvas width and height from the ref
			const canvasWidth  = contentRef.current.offsetWidth
			const canvasHeight = contentRef.current.offsetHeight

			const engine = Engine.create()
			const render = Render.create({
				                             element: canvasRef.current,
				                             engine:  engine,
				                             options: {
					                             width:      canvasWidth,
					                             height:     canvasHeight,
					                             wireframes: false,
				                             },
			                             })

			render.canvas.classList.add(styles.matter_canvas)

			//region Create boxes
			const width        = isMobile
			                     ? 100
			                     : 200
			const height       = isMobile
			                     ? 40
			                     : 80
			const borderRadius = 10

			// Random number between 0 and canvas width

			function createBox(color) {
				const randomX = Math.floor(Math.random()
				                           * (
					                           canvasWidth
					                           - width
					                           / 2
				                           )
				                           + width
				                           / 2)
				const randomY = Math.floor(Math.random()
				                           * (
					                           canvasHeight
					                           / 5
					                           - height
					                           / 2
				                           )
				                           + height
				                           / 2)
				return Bodies.rectangle(
					randomX,
					randomY,
					width,
					height,
					{
						isStatic: false,
						chamfer:  {radius: borderRadius},
						render:   {fillStyle: color},
					},
				)
			}

			// Create boxes
			var box1 = createBox('#3B4156')
			var box2 = createBox('#D36F3B')
			var box3 = createBox('#6BAF91')
			var box4 = createBox('#611A1E')
			var box5 = createBox('#F5AE39')

			// Create a Pixi.js container for the body
			var renderer = new PIXI.Renderer({
				                                 width:           canvasWidth,
				                                 height:          canvasHeight,
				                                 transparent:     true,
				                                 backgroundColor: 'transparent',
				                                 backgroundAlpha: 0,
			                                 })
			// Set background transparent
			renderer.view.classList.add(styles.pixi_canvas)

			// Append the Pixi.js renderer to the DOM
			canvasRef.current.appendChild(renderer.view)

			// Create a Pixi.js stage
			const stage = new PIXI.Container()

			// Texts
			const createText = () => {
				const text = new PIXI.Text(
					'Erreur 404',
					{
						fontFamily: 'Arial',
						fontSize:   isMobile
						            ? 16
						            : 24,
						fill:       'white',
						align:      'center',
					},
				)
				text.pivot.set(
					text.width
					/ 2,
					text.height
					/ 2,
				)

				return text
			}
			// Create texts
			const text1      = createText()
			const text2      = createText()
			const text3      = createText()
			const text4      = createText()
			const text5      = createText()

			//region Mouse constraint
			const mouse           = Mouse.create(render.canvas)
			const mouseConstraint = MouseConstraint.create(
				engine,
				{
					mouse:      mouse,
					constraint: {
						stiffness: 0.2,
						render:    {
							visible: false,
						},
					},
				},
			)
			World.add(
				engine.world,
				mouseConstraint,
			)
			//endregion

			//region Walls
			//region Create walls
			const topWall    = Bodies.rectangle(
				canvasWidth
				/ 2,
				0,
				canvasWidth,
				2,
				{
					isStatic: true,
					render:   {
						visible: false,
					},
				},
			)
			const bottomWall = Bodies.rectangle(
				canvasWidth
				/ 2,
				canvasHeight,
				canvasWidth,
				2,
				{
					isStatic: true,
					render:   {
						visible: false,
					},
				},
			)
			const leftWall   = Bodies.rectangle(
				0,
				canvasHeight
				/ 2,
				2,
				canvasHeight,
				{
					isStatic: true,
					render:   {
						visible: false,
					},
				},
			)
			const rightWall  = Bodies.rectangle(
				canvasWidth,
				canvasHeight
				/ 2,
				2,
				canvasHeight,
				{
					isStatic: true,
					render:   {
						visible: false,
					},
				},
			)
			//endregion

			const walls = [
				topWall,
				rightWall,
				bottomWall,
				leftWall,
			]

			// Add walls into the world
			World.add(
				engine.world,
				walls,
			)

			function wallHandleResize() {
				return () => {
					const canvasWidth  = contentRef.current.offsetWidth
					const canvasHeight = contentRef.current.offsetHeight
					console.log(
						canvasWidth,
						canvasHeight,
					)

					//region Update walls position
					Body.setPosition(
						bottomWall,
						{
							x: canvasWidth
							   / 2,
							y: canvasHeight,
						},
					)
					Body.setPosition(
						rightWall,
						{
							x: canvasWidth,
							y: canvasHeight
							   / 2,
						},
					)
					//endregion

					// Update MatterJS render size
					render.canvas.width  = canvasWidth
					render.canvas.height = canvasHeight

					// Update PixiJS render size
					renderer.resize(
						canvasWidth,
						canvasHeight,
					)
				}
			}

			//region Handle resize event to update walls position and render size
			window.addEventListener(
				'resize',
				wallHandleResize(),
			)
			//endregion
			//endregion

			function animate() {
				requestAnimationFrame(animate)

				//region Texts
				// Update the rotation of the text to follow the rectangle body but do it from the center
				// Text 1
				text1.x        = box1.position.x
				text1.y        = box1.position.y
				text1.rotation = box1.angle
				// Text 2
				text2.x        = box2.position.x
				text2.y        = box2.position.y
				text2.rotation = box2.angle
				// Text 3
				text3.x        = box3.position.x
				text3.y        = box3.position.y
				text3.rotation = box3.angle
				// Text 4
				text4.x        = box4.position.x
				text4.y        = box4.position.y
				text4.rotation = box4.angle
				// Text 5
				text5.x        = box5.position.x
				text5.y        = box5.position.y
				text5.rotation = box5.angle
				//endregion

				// Render the stage with Pixi.js
				renderer.render(stage)
			}

			animate()

			Engine.run(engine)
			Render.run(render)


			setTimeout(
				() => {
					// Add boxes to the world
					World.add(
						engine.world,
						[
							box1,
							box2,
							box3,
							box4,
							box5,
						],
					)

					// Add texts to PixiJS stage
					stage.addChild(
						text1,
						text2,
						text3,
						text4,
						text5,
					)
				},
				isMobile
				? 2000
				: 3500,
			)

			return () => {
				engine.world.bodies.forEach(body => {
					World.remove(
						engine.world,
						body,
					)
				})

				Render.stop(render)
				Engine.clear(engine)
				canvasRef?.current?.remove()

				window.removeEventListener(
					'resize',
					wallHandleResize(),
				)
			}
		},
		[],
	)

	return (
		<div
			ref={canvasRef}
			className={styles.canvas_container}
		/>
	)
}

export default Page404
